/**
 *
 * '||''|.                            '||
 *  ||   ||    ....  .... ...   ....   ||    ...   ... ...  ... ..
 *  ||    || .|...||  '|.  |  .|...||  ||  .|  '|.  ||'  ||  ||' ''
 *  ||    || ||        '|.|   ||       ||  ||   ||  ||    |  ||
 * .||...|'   '|...'    '|     '|...' .||.  '|..|'  ||...'  .||.
 *                                                  ||
 * --------------- By Display:inline ------------- '''' -----------
 *
 * Login pages stylesheet
 */
html {
	min-height: 100%;
}
body {
	text-align: center;
	color: white;
}

/* Layout */
#container {
	width: 280px;
	padding: 0 20px;
	margin: 0 auto;
	text-align: left;
	padding-top: 25px;
	}
	#container input {
		width: 212px;
	}
	#container input[type="radio"],
	#container input[type="checkbox"] {
		width: auto !important;
	}
	#container input[type="text"],
	#container input[type="password"] {
		-webkit-appearance: none;
	}
#form-block {
	position: relative;
	}
	#form-block.scratch-metal {
		padding: 11px 10px;
		-webkit-border-radius: 7px;
		-moz-border-radius: 7px;
		border-radius: 7px;
		-webkit-box-shadow: inset 0 1px 0 white, 0 2px 7px rgba(0, 0, 0, 0.75);
		-moz-box-shadow: inset 0 1px 0 white, 0 2px 7px rgba(0, 0, 0, 0.75);
		box-shadow: inset 0 1px 0 white, 0 2px 7px rgba(0, 0, 0, 0.75);
		}
		.no-boxshadow #form-block.scratch-metal {
			border: 1px solid #808080;
			border-top-color: #cccccc;
		}
		#form-block.scratch-metal input {
			width: 172px;
		}
		.no-boxshadow #form-block.scratch-metal input {
			width: 168px;
		}
	#form-viewport {
		position: relative;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		border-radius: 4px;
	}
	#form-switch {
		text-align: center;
		margin: 20px 0 0;
		}
		#form-block.scratch-metal #form-switch {
			margin: 10px -10px -11px;
			border-top: 1px solid #808080;
			padding: 11px 10px 10px;
			-webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.75);
			-moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.75);
			box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.75);
		}
form {
	margin-bottom: 11px;
	}
	form:last-child {
		margin-bottom: 0;
	}
	.no-boxshadow #form-viewport form {
		border-width: 1px;
		border-style: solid;
	}
#login-title {
	text-align: center;
	}
	#login-title h1 {
		font-size: 48px;
		line-height: 55px;
		margin: 0;
		color: white;
		-webkit-text-shadow: 0 2px 7px rgba(0, 0, 0, 0.5);
		-moz-text-shadow: 0 2px 7px rgba(0, 0, 0, 0.5);
		text-shadow: 0 2px 7px rgba(0, 0, 0, 0.5);
		}
		#login-title h1.login-title-image {
			background: url('../img/login-title.png') no-repeat center center;
			overflow: hidden;
			height: 78px;
			text-align: left;
			text-indent: -3000px;
		}
	#login-title h5 {
		color: #c8cfd5;
		margin-top: -6px;
	}

/* Links / buttons */
#login {
	width: 80px;
}

/* Doors */
.form-door {
	display: block;
	position: absolute;
	zoom: 1;
	z-index: 90;
	left: 0;
	right: 0;
	height: 50%;
	border-left: 1px solid #999999;
	border-right: 1px solid #999999;
	-webkit-transition: all 250ms linear;
	-moz-transition: all 250ms linear;
	-ms-transition: all 250ms linear;
	-o-transition: all 250ms linear;
	transition: all 250ms linear;
	}
	#top-door {
		top: -55%;
		-webkit-border-radius: 4px 4px 0 0;
		-moz-border-radius: 4px 4px 0 0;
		border-radius: 4px 4px 0 0;
		background: #dedede url('../img/textures/scratches.png');
		background: url('../img/textures/scratches.png') repeat left bottom, -webkit-gradient(linear, left top, left bottom, from(#aaaaaa), to(#ffffff), color-stop(0.59, #f2f2f2));
		background: url('../img/textures/scratches.png') repeat left bottom, -webkit-linear-gradient(top, #aaaaaa, #f2f2f2 59%, #ffffff);
		background: url('../img/textures/scratches.png') repeat left bottom, -moz-linear-gradient(top, #aaaaaa, #f2f2f2 59%, #ffffff);
		background: url('../img/textures/scratches.png') repeat left bottom, -ms-linear-gradient(top, #aaaaaa, #f2f2f2 59%, #ffffff);
		background: url('../img/textures/scratches.png') repeat left bottom, -o-linear-gradient(top, #aaaaaa, #f2f2f2 59%, #ffffff);
		background: url("../img/textures/scratches.png") repeat left bottom, linear-gradient(top, #aaaaaa, #f2f2f2 59%, #ffffff);
		-webkit-box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.35), inset 0 -1px 0 rgba(0, 0, 0, 0.35);
		-moz-box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.35), inset 0 -1px 0 rgba(0, 0, 0, 0.35);
		box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.35), inset 0 -1px 0 rgba(0, 0, 0, 0.35);
		}
		#top-door.door-down {
			top: 0;
		}
		#top-door.door-closed {
			-webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.75), inset 0 -1px 0 rgba(0, 0, 0, 0.35);
			-moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.75), inset 0 -1px 0 rgba(0, 0, 0, 0.35);
			box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.75), inset 0 -1px 0 rgba(0, 0, 0, 0.35);
		}
	#bot-door {
		top: 105%;
		-webkit-border-radius: 0 0 4px 4px;
		-moz-border-radius: 0 0 4px 4px;
		border-radius: 0 0 4px 4px;
		background: #dedede url('../img/textures/scratches.png');
		background: url('../img/textures/scratches.png'), -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#dedede), color-stop(0.73, #c5c5c5));
		background: url('../img/textures/scratches.png'), -webkit-linear-gradient(top, #ffffff, #c5c5c5 73%, #dedede);
		background: url('../img/textures/scratches.png'), -moz-linear-gradient(top, #ffffff, #c5c5c5 73%, #dedede);
		background: url('../img/textures/scratches.png'), -ms-linear-gradient(top, #ffffff, #c5c5c5 73%, #dedede);
		background: url('../img/textures/scratches.png'), -o-linear-gradient(top, #ffffff, #c5c5c5 73%, #dedede);
		background: url("../img/textures/scratches.png") repeat, linear-gradient(top, #ffffff, #c5c5c5 73%, #dedede);
		-webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.75), inset 0 -4px 4px rgba(0, 0, 0, 0.35);
		-moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.75), inset 0 -4px 4px rgba(0, 0, 0, 0.35);
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.75), inset 0 -4px 4px rgba(0, 0, 0, 0.35);
		}
		#bot-door.door-down {
			top: 50%;
		}
		#bot-door.door-closed {
			-webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.75), inset 0 -1px 0 rgba(0, 0, 0, 0.35);
			-moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.75), inset 0 -1px 0 rgba(0, 0, 0, 0.35);
			box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.75), inset 0 -1px 0 rgba(0, 0, 0, 0.35);
		}

	.form-door div {
		position: absolute;
		left: 50%;
		margin-left: -64px;
		height: 64px;
		width: 128px;
		background: url('../img/watermark.png') no-repeat center top;
		}
		#top-door div {
			bottom: 0;
		}
		#bot-door div {
			top: 0;
			background-position: center bottom;
		}

/* JS login styles */
.js-login #form-wrapper {
	padding-bottom: 20px;
}
.js-login #form-viewport {
	overflow: hidden;
	-webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.75), 0 -1px 0 rgba(0, 0, 0, 0.35);
	-moz-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.75), 0 -1px 0 rgba(0, 0, 0, 0.35);
	box-shadow: 0 1px 0 rgba(255, 255, 255, 0.75), 0 -1px 0 rgba(0, 0, 0, 0.35);
}
.js-login form {
	margin-bottom: 0;
}

/*768px __________________________________________________________________________________________________________ */
@media only screen and (min-width: 768px) {

	html {
		height: auto;
		min-height: 100%;
		overflow: auto;
		}
		html.js-login {
			height: 100%;
			overflow: hidden;
		}
	body {
		height: auto;
		overflow: auto;
		}
		.js-login body {
			height: 100%;
			overflow: hidden;
		}

	/* Animation styles */
	.js-login #container {
		position: absolute;
		top: 50%;
		left: 50%;
		padding: 0;
		margin-left: -140px;
	}

}/*/mediaquery*/

/*2x __________________________________________________________________________________________________________ */
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {



}/*/mediaquery*/